<template>
  <div class="categoryForm">
    <form v-on:submit.prevent="saveChanges">
      <div class="form-group">
        <label for="marketplace_related_video">Select Related Video: </label>
        <select
          :class="{
            'form-control': true,
            'is-invalid': errors.has('marketplace_related_video')
          }"
          name="marketplace_related_video"
          id="marketplace_related_video"
          v-model="assignment.video"
          v-validate="'required'"
        >
          <option
            v-for="video in videos"
            v-bind:value="video.id"
            v-bind:key="video.id"
          >
            {{ video.title }}
          </option>
        </select>
        <div class="invalid-feedback">
          Please provide a valid related video selection.
        </div>
      </div>
      <div class="form-group">
        <label for="marketplace_product">Select Marketplace Product: </label>
        <select
          :class="{
            'form-control': true,
            'is-invalid': errors.has('marketplace_product')
          }"
          name="marketplace_product"
          id="marketplace_product"
          v-model="assignment.product"
          v-validate="'required'"
        >
          <option
            v-for="product in products"
            v-bind:value="product.id"
            v-bind:key="product.id"
          >
            {{ product.title }}
          </option>
        </select>
        <div class="invalid-feedback">
          Please provide a valid product selection.
        </div>
      </div>

      <div class="form-group">
        <button class="btn btn-primary">Save</button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  methods: {
    saveChanges: function() {
      this.$validator.validateAll().then(result => {
        if (result) {
          let token = this.$cookies.get("poolfit_admin_session");

          if (token !== null) {
            let options = {
              headers: { "X-Access-Token": token }
            };

            this.$http
              .post(
                process.env.VUE_APP_ROOT_API + "/marketplace/related/video",
                this.assignment,
                options
              )
              .then(response => {
                if (response.status === 200) {
                  alert("Record created successfully");
                }
              });
          }
        }
      });
    }
  },

  data: function() {
    return {
      products: {},
      videos: {},
      assignment: {}
    };
  },

  created: function() {
    this.$http
      .get(process.env.VUE_APP_ROOT_API + "/marketplace")
      .then(response => {
        this.products = response.data;
      });

    this.$http.get(process.env.VUE_APP_ROOT_API + "/videos").then(response => {
      this.videos = response.data;
    });
  }
};
</script>
<style></style>
