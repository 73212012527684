var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"categoryForm"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.saveChanges.apply(null, arguments)}}},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"marketplace_related_video"}},[_vm._v("Select Related Video: ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.assignment.video),expression:"assignment.video"},{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:{
          'form-control': true,
          'is-invalid': _vm.errors.has('marketplace_related_video')
        },attrs:{"name":"marketplace_related_video","id":"marketplace_related_video"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.assignment, "video", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.videos),function(video){return _c('option',{key:video.id,domProps:{"value":video.id}},[_vm._v(" "+_vm._s(video.title)+" ")])}),0),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please provide a valid related video selection. ")])]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"marketplace_product"}},[_vm._v("Select Marketplace Product: ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.assignment.product),expression:"assignment.product"},{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:{
          'form-control': true,
          'is-invalid': _vm.errors.has('marketplace_product')
        },attrs:{"name":"marketplace_product","id":"marketplace_product"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.assignment, "product", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.products),function(product){return _c('option',{key:product.id,domProps:{"value":product.id}},[_vm._v(" "+_vm._s(product.title)+" ")])}),0),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please provide a valid product selection. ")])]),_vm._m(0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('button',{staticClass:"btn btn-primary"},[_vm._v("Save")])])
}]

export { render, staticRenderFns }